import { gql } from '@apollo/client';
import { useQuery, QueryHookOptions } from '@apollo/client';
import { requiredUserInputFieldsQuery } from '../../core-types';

export const REQUIRED_USER_INPUT_FIELDS_QUERY = gql`
  query requiredUserInputFieldsQuery {
    requiredUserInputFields {
      email
    }
  }
`;

export const useRequiredUserInputFields = (
  options?: QueryHookOptions<requiredUserInputFieldsQuery>
) =>
  useQuery<requiredUserInputFieldsQuery>(
    REQUIRED_USER_INPUT_FIELDS_QUERY,
    options
  );
