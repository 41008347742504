import { useState, useEffect } from 'react';
import { useOnboardingSteps } from '../modules/Onboarding/useOnboardingSteps';
import Link from '../components/Link';
import { Text, Box, I18NLogOut, I18NContinue } from '../components';
import {
  DialogBackdrop,
  DialogWrapper,
  ButtonAnchor,
  AnchorButton,
  ScrollLock
} from '@urbaninfrastructure/react-ui-kit';
import { InfoAlt } from '@urbaninfrastructure/react-icons';
import { FormattedMessage } from 'react-intl';
import { useOnboardingQuery } from '../modules/Onboarding/useOnboardingQuery';
import { useAuth } from '../lib/with-auth';
import { useCurrentUser } from '../lib/core/useCurrentUser';

const MissingOnboardingDialog = () => {
  const { currentUser } = useCurrentUser();
  const [showOnboarding, setShowOnboarding] = useState(false);
  const onboardingSteps = useOnboardingSteps(currentUser, true);
  const currentUserNeedsOnboarding = onboardingSteps.steps
    ? onboardingSteps.steps.length > 0
    : false;
  // preload onboarding query
  useOnboardingQuery({ skip: !showOnboarding });
  const { logOut } = useAuth();

  useEffect(() => {
    if (currentUserNeedsOnboarding) {
      const scrollLock = new ScrollLock();
      scrollLock.on();
      setShowOnboarding(true);
      return () => {
        scrollLock.off();
      };
    }
  }, [currentUserNeedsOnboarding]);

  if (!showOnboarding) {
    return null;
  }

  return (
    <>
      <DialogWrapper>
        <Box
          data-testid="MissingOnboardingDialog"
          bg="white"
          p="sm"
          borderRadius="md"
          textAlign="center"
          maxWidth={400}
        >
          <InfoAlt color="primaryIcon" size={36} mb={2} />
          <Text as="p">
            <FormattedMessage
              id="MissingOnboardingDialog.completeProfile"
              defaultMessage="You need to complete your profile"
            />
          </Text>
          <div>
            <Link route="onboarding" passHref>
              <ButtonAnchor shape="cool" variant="primary" mb="xs">
                <I18NContinue />
              </ButtonAnchor>
            </Link>
          </div>
          <AnchorButton
            typoStyle="xxs"
            mute
            textDecoration="none"
            onClick={() => {
              logOut();
              setShowOnboarding(false);
            }}
          >
            <I18NLogOut />
          </AnchorButton>
        </Box>
      </DialogWrapper>
      <DialogBackdrop />
    </>
  );
};

// Must be default exported, to support Next.js dynamic import
export default MissingOnboardingDialog;
