import { gql } from '@apollo/client';
import { useQuery, QueryHookOptions } from '@apollo/client';
import { onboardingQuery } from '../../core-types';

export const ONBOARDING_QUERY = gql`
  query onboardingQuery {
    buyProductPage {
      termsOfConditionItems {
        _key
        title
        body
        type
      }
    }
  }
`;

export const useOnboardingQuery = (
  options?: QueryHookOptions<onboardingQuery>
) => useQuery<onboardingQuery>(ONBOARDING_QUERY, options);
