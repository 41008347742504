import { useRequiredUserInputFields } from '../../lib/core/requiredUserInputFieldsQuery';
import { useSettingsQuery } from '../../lib/core/SettingsQuery';
import { ApolloError } from '@apollo/client';
import isWithinInterval from 'date-fns/isWithinInterval';
import { CurrentUser } from '../../core-types';

export type StepId =
  | 'agreedToTerms'
  | 'paymentMethod'
  | 'name'
  | 'birthYear'
  | 'gender'
  | 'postalCode'
  | 'email'
  | 'obos';

export type Step = { id: StepId; required: boolean };

/**
 *
 * @param onlyRequiredFields only include required fields
 * @param stepsToInclude include these steps even if not required
 */
export function useOnboardingSteps(
  currentUser: CurrentUser | null,
  onlyRequiredFields?: boolean,
  stepsToInclude?: string[]
): {
  steps?: Step[];
  loading: boolean;
  error?: ApolloError;
} {
  const steps: Step[] = [
    { id: 'agreedToTerms', required: true },
    { id: 'paymentMethod', required: false },
    {
      id: 'name',
      required: false
    },
    {
      id: 'birthYear',
      required: false
    },
    {
      id: 'gender',
      required: false
    },
    {
      id: 'postalCode',
      required: false
    },
    { id: 'email', required: false },
    { id: 'obos', required: false }
  ];

  const settingsQueryProps = useSettingsQuery();
  const system = settingsQueryProps?.data?.system;
  const requiredUserInputResult = useRequiredUserInputFields({
    skip: !currentUser
  });
  let emailIsRequired =
    requiredUserInputResult?.data?.requiredUserInputFields?.email || false;
  const loading = settingsQueryProps.loading || requiredUserInputResult.loading;
  const error = settingsQueryProps.error || requiredUserInputResult.error;

  if (loading || !system || !currentUser) {
    return {
      loading,
      error
    };
  }

  // HACK START: Hack for OBOS free week for Bergen 2020. Remove after 2020-06-22.
  if (
    // only apply hack for bergen-city-bike
    system.id === 'bergen-city-bike'
  ) {
    if (
      // email must not be set
      !currentUser.email &&
      currentUser.currentSubscription &&
      currentUser.currentSubscription.createdAt &&
      currentUser.currentSubscription.product &&
      // subscription product must be free
      currentUser.currentSubscription.product.price === 0 &&
      // subscription must be created between 2020-06-12 and 2020-06-23 (start of day)
      isWithinInterval(new Date(currentUser.currentSubscription.createdAt), {
        start: new Date(2020, 5, 12),
        end: new Date(2020, 5, 23)
      })
    ) {
      emailIsRequired = true;
    } else {
      // otherwise, email is not required even if requiredUserInputFields.email says so
      emailIsRequired = false;
    }
  }
  // HACK END

  // first, update the required property of the steps based on system props
  const onboardingSteps = steps.reduce<Step[]>((accum, step) => {
    if (step.id === 'obos' && system.sponsor !== 'obos') {
      return accum;
    } else if (step.id === 'email' && emailIsRequired) {
      step.required = true;
    } else if (step.id === 'paymentMethod') {
      if (currentUser.requiresAddingPaymentCard) {
        step.required = true;
      } else if (
        currentUser.paymentMethod?.__typename === 'Card' &&
        currentUser.paymentMethod?.expired
      ) {
        step.required = true;
      }
    }
    accum.push(step);
    return accum;
  }, []);

  // then, filter out steps that the user already have completed
  const filteredSteps = onboardingSteps.filter(step => {
    // always return the step if included in stepsToInclude
    if (stepsToInclude?.includes(step.id)) {
      return true;
    }

    let keepStep = false;

    if (step.id === 'paymentMethod') {
      keepStep = Boolean(currentUser.requiresAddingPaymentCard);
      if (
        currentUser.currentSubscription &&
        currentUser.paymentMethod?.__typename === 'Card' &&
        currentUser.paymentMethod?.expired
      ) {
        keepStep = true;
      }
    } else if (
      step.id === 'email' &&
      (currentUser.agreedToReceiveEmail === null ||
        currentUser.agreedToReceiveExternalPromotions === null)
    ) {
      keepStep = true;
    } else {
      keepStep = !currentUser[step.id];
    }

    if (onlyRequiredFields) {
      return keepStep && step.required;
    }
    return keepStep;
  });

  return {
    steps: filteredSteps,
    loading,
    error
  };
}

export function useUserShouldOnboard(
  currentUser: CurrentUser | null,
  onlyRequiredFields?: boolean,
  stepsToInclude?: string[]
): {
  loading: boolean;
  userRequiresOnboarding: boolean;
} {
  const onboardingSteps = useOnboardingSteps(
    currentUser,
    onlyRequiredFields,
    stepsToInclude
  );
  return {
    loading: onboardingSteps.loading,
    userRequiresOnboarding:
      onboardingSteps.steps && onboardingSteps.steps.length > 0 ? true : false
  };
}
